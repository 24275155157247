/* eslint-disable consistent-return */
import {
  createSlice,
} from '@reduxjs/toolkit';
import axios, {
  AxiosRequestConfig,
} from 'axios';

import {
  Nullable,
  SelectOption,
  SortOrder,
  TableState,
} from '@/core/interfaces/common';
import { RootState } from '@/core/interfaces/store';
import { AppThunk } from '@/core/store/store';

import { request } from '@/utils/request';

const { CLIENT_ID } = process.env;

const clientId = CLIENT_ID;

export type GetAllTagsResponse = {
  message?: string;
  tags?: Array<string>;
}

export type ComparedClaimsResponse = {
  metadata?: Metadata;
  similarityIndex?: number;
  claim_1?: ComparedClaim;
  claim_2?: ComparedClaim;
}

export type ComparedClaim = {
  claimId: number;
  claimNumber: string;
  claimAgeDays: number;
  claimStatus: string;
  location: string;
  bodyPart: string;
  natureInjury: string;
  causeInjury: string;
  claimantAge: number;
  closeLag: string;
  mri: number;
  surgery: number;
  comorbidity: number;
  attorney: number;
  paidMedical: number;
  paidIndemnity: number;
  paidOther: number;
  paidDate: number;
  paidTotal: number;
  incurredToDate: number;
  lostTime: boolean;
  score: number;
}

type Metadata = {
  message: string;
}

type SimilarClaimsResponse = {
  metadata?: MetaData;
  claimStatistics?: ClaimStatistics;
  data?: SingleSimilarClaimsList;
  currentClaim?: SingleSimilarClaimsList;
}

export type SingleSimilarClaimsList = Array<SingleSimilarClaimData>;

export type SingleSimilarClaimData = {
  claimId: number;
  claimNumber: string;
  natureInjury: string;
  bodyPart: string;
  surgeryTm: number;
  attorneyTm: number;
  causeInjury: string;
  location: string;
  paidMedical: number;
  paidIndemnity: number;
  paidTotal: number;
  paidTotalUltimate: number;
  totalIncurred: number;
  claimantAge: number;
  score: number;
  similarityIndex: number;
}

type ClaimStatistics = {
  paidAtUltimate: StatisticsArray;
  closeLag: StatisticsArray;
  top_5SimilarClaims: Array<Top5_SimilarClaim>;
  graphSizeSimilarClaims: GraphSizeSimilarClaims;
}

export interface Top5_SimilarClaim {
  claimId: number;
  claimNumber: string;
  paidTotalUltimate: number;
  incurredTotalUltimate: number;
}

export interface GraphSizeSimilarClaims {
  paid: Incurred;
  incurred: Incurred;
}

export interface Incurred {
  small: number;
  medium: number;
  large: number;
  veryLarge: number;
  total?: number;
}

type StatisticsArray = {
  [key: string]: StatisticsItem;
}

type StatisticsItem = {
  average: string;
  median: string;
  minimum: string;
  maximum: string;
}

export type ScoreColorSchema = {
  message?: string;
  lowThreshold: Threshold;
  medThreshold: Threshold;
  highThreshold: Threshold;
}

type Threshold = {
  start: number;
  end: number;
  color: string;
}

export type MetaData = {
  totalRecordCount: number;
  pages: number;
  currentPage: number;
  message?: string;
  detailMessage?: string;
  lastUpdated?: string;
}

export type ClaimListItem = {
  claimId: number;
  claimNumber: string;
  dateLoss: string;
  dateReported: string;
  dateClosed: Nullable<string>;
  causeInjury: string;
  natureInjury: string;
  bodyPart: string;
  jobPosition: string;
  department: string;
  claimantName: string;
  claimantAge: Nullable<number>;
  claimantGender: string;
  claimantDob: string;
  location: string;
  claimStatus: string;
  claimAdjuster: string;
  claimAgeDays: string;
  score: string;
  totalPaid: string;
  totalReserves: string;
  totalIncurred: string;
  star: boolean;
  tag: Array<string>;
  alerts: Array<string>;
}

export type ClaimList = {
  metadata?: MetaData;
  data?: { [id: string]: ClaimListItem };
}

export type TimePeriod = {
    last_30Days: string;
    last_90Days: string;
    lastYear: string;
    customDateRange: string;
}

export type FilterOptions = {
  message?: string;
  adjusterList?: Array<string>;
  bodyPartsList?: Array<string>;
  locationList?: Array<string>;
  claimStatusList?: Array<string>;
  timePeriod?: TimePeriod;
  score?: {
      max?: number;
      min?: number;
  };
  amountTypeList?: Array<SelectOption>;
  amountValues?: {
      from?: number;
      to?: number;
  };
}

export type FiltersApplied = {
  adjusterName?: string;
  bodyPartsOptions?: string;
  state?: string;
  claimStatus?: string;
  dateReportedStart?: string;
  dateReportedEnd?: string;
  scoreStart?: number;
  scoreEnd?: number;
  amountType?: string;
  amountStart?: number;
  amountEnd?: number;
  tag?: string;
  timePeriod?: TimePeriod;
  starredClaimsOnly?: boolean;
}

export type ClaimsState = {
  claimList: ClaimList;
  similarClaims: SimilarClaimsResponse;
  comparison: ComparedClaimsResponse;
  error: Nullable<string>;
  filterOptions?: FilterOptions;
  filtersApplied: FiltersApplied;
  isLoading: boolean;
  isSimilarClaimsLoading: boolean;
  isFilterOptionsLoading: boolean;
  scoreColorSchema: ScoreColorSchema;
  isScoreColorSchemaLoading: boolean;
  isComparisonLoading: boolean;
  isTagsLoading: boolean;
  tags?: GetAllTagsResponse;
  isStarClaimLoading: boolean;
  claimListTableState: TableState;
  firstRender?: boolean;
}

const initialState: ClaimsState = {
  claimList: {},
  similarClaims: {},
  error: null,
  filtersApplied: {},
  filterOptions: {},
  comparison: {},
  isLoading: false,
  isSimilarClaimsLoading: false,
  isFilterOptionsLoading: false,
  isScoreColorSchemaLoading: false,
  isComparisonLoading: false,
  isTagsLoading: false,
  tags: {},
  isStarClaimLoading: false,
  claimListTableState: {
    page: 1,
    itemsPerPage: 20,
    sortBy: 'score',
    sortOrder: SortOrder.descending,
    firstRender: true,
  },
  scoreColorSchema: {
    lowThreshold: {
      start: 1,
      end: 799,
      color: '#00A663',
    },
    medThreshold: {
      start: 800,
      end: 899,
      color: '#FFA200',
    },
    highThreshold: {
      start: 900,
      end: 1000,
      color: '#A52040',
    },
  },
  firstRender: false,
};

const claims = createSlice({
  name: 'claims',
  initialState,
  reducers: {
    getClaimListRequest(state) {
      state.isLoading = true;
    },
    getClaimListSuccess(state, action) {
      state.claimList = action.payload;
      state.isLoading = false;
    },
    getClaimListFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getClaimListCancelled(state) {
      state.error = 'request cancelled';
    },
    resetClaimListPaginationData(state) {
      state.claimList.metadata = {
        ...state.claimList.metadata,
        totalRecordCount: 0,
        pages: 0,
        currentPage: 0,
      };
    },
    setClaimListTableState: (state, action) => ({
      ...state,
      claimListTableState: {
        ...state.claimListTableState,
        ...(action?.payload || {}),
      },
    }),
    getSimilarClaimsRequest(state) {
      state.similarClaims = {};
      state.isSimilarClaimsLoading = true;
    },
    getSimilarClaimsSuccess(state, action) {
      state.similarClaims = action.payload;
      state.isSimilarClaimsLoading = false;
    },
    getSimilarClaimsFailure(state, action) {
      state.isSimilarClaimsLoading = false;
      state.error = action.payload;
    },
    getFilterOptionsRequest(state) {
      state.isFilterOptionsLoading = true;
    },
    getFilterOptionsSuccess(state, action) {
      state.filterOptions = {
        ...state.filterOptions,
        ...action.payload,
      };
      state.isFilterOptionsLoading = false;
    },
    getFilterOptionsFailure(state, action) {
      state.isFilterOptionsLoading = false;
      state.error = action.payload;
    },
    getFilterTimePeriodSuccess(state, action) {
      state.filterOptions = {
        ...state.filterOptions,
        timePeriod: {
          ...action.payload,
        },
      };
      state.isFilterOptionsLoading = false;
    },
    getScoreColorSchemaRequest(state) {
      state.isScoreColorSchemaLoading = true;
    },
    getScoreColorSchemaSuccess(state, action) {
      state.isScoreColorSchemaLoading = false;
      state.scoreColorSchema = action.payload;
    },
    getScoreColorSchemaFailure(state, action) {
      state.isScoreColorSchemaLoading = false;
      state.error = action.payload;
    },
    getClaimsComparisonRequest(state) {
      state.isComparisonLoading = true;
    },
    getClaimsComparisonSuccess(state, action) {
      state.isComparisonLoading = false;
      state.comparison = action.payload;
    },
    getClaimsComparisonFailure(state, action) {
      state.isComparisonLoading = false;
      state.error = action.payload;
    },
    resetClaimsComparison(state) {
      state.comparison = {};
    },
    setClaimTagsRequest(state) {
      state.isTagsLoading = true;
    },
    setClaimTagsSuccess(state, action) {
      state.isTagsLoading = false;

      const claimIdIndex = Object.entries(state.claimList.data || {}).filter(([
        key,
        claim,
      ]) => claim.claimId === action.payload.claimId)
        .flat()?.[0];

      if (claimIdIndex && state.claimList.data?.[String(claimIdIndex)]) {
        state.claimList.data = {
          ...state.claimList.data,
          [String(claimIdIndex)]: {
            ...state.claimList.data?.[String(claimIdIndex)],
            tag: action.payload.tags,
          },
        };
      }
    },
    setClaimTagsFailure(state, action) {
      state.isTagsLoading = false;
    },
    getAllTagsRequest(state) {
      state.isTagsLoading = true;
    },
    getAllTagsSuccess(state, action) {
      state.isTagsLoading = false;
      state.tags = action.payload;
    },
    getAllTagsFailure(state) {
      state.isTagsLoading = false;
    },
    starClaimRequest(state, action) {
      state.isStarClaimLoading = true;

      const claimIdIndex = Object.entries(state.claimList.data || {}).filter(([
        key,
        claim,
      ]) => claim.claimId === action.payload.claimId)
        .flat()?.[0];

      if (claimIdIndex && state.claimList.data?.[String(claimIdIndex)]) {
        state.claimList.data = {
          ...state.claimList.data,
          [String(claimIdIndex)]: {
            ...state.claimList.data?.[String(claimIdIndex)],
            star: true,
          },
        };
      }
    },
    starClaimSuccess(state) {
      state.isStarClaimLoading = false;
    },
    starClaimFailure(state, action) {
      state.isStarClaimLoading = false;
      state.error = action.payload;
    },
    unStarClaimRequest(state, action) {
      state.isStarClaimLoading = true;

      const claimIdIndex = Object.entries(state.claimList.data || {}).filter(([
        key,
        claim,
      ]) => claim.claimId === action.payload.claimId)
        .flat()?.[0];

      if (claimIdIndex && state.claimList.data?.[String(claimIdIndex)]) {
        state.claimList.data = {
          ...state.claimList.data,
          [String(claimIdIndex)]: {
            ...state.claimList.data?.[String(claimIdIndex)],
            star: false,
          },
        };
      }
    },
    unStarClaimSuccess(state) {
      state.isStarClaimLoading = false;
    },
    unStarClaimFailure(state, action) {
      state.isStarClaimLoading = false;
      state.error = action.payload;
    },

    setLoadingAction(state, action) {
      state.isLoading = action.payload;
    },
    setFiltersApplied(state, {
      payload: {
        firstRender,
        ...rest
      },
    }) {
      state.filtersApplied = rest;
      state.firstRender = firstRender;
    },
    resetMemoParams: state => ({
      ...state,
      claimListTableState: {
        page: 1,
        itemsPerPage: 20,
        sortBy: 'score',
        sortOrder: SortOrder.descending,
        firstRender: true,
      },
      filtersApplied: {},
      firstRender: true,
    }),
  },
});

export const {
  getClaimListCancelled,
  getClaimListFailure,
  getClaimListRequest,
  getClaimListSuccess,
  resetClaimListPaginationData,
  getSimilarClaimsFailure,
  getSimilarClaimsRequest,
  getSimilarClaimsSuccess,
  getFilterOptionsRequest,
  getFilterOptionsSuccess,
  getFilterOptionsFailure,
  getScoreColorSchemaRequest,
  getScoreColorSchemaSuccess,
  getScoreColorSchemaFailure,
  getClaimsComparisonRequest,
  getClaimsComparisonSuccess,
  getClaimsComparisonFailure,
  setFiltersApplied,
  setClaimTagsRequest,
  setClaimTagsSuccess,
  setClaimTagsFailure,
  setClaimListTableState,
  resetClaimsComparison,
  getAllTagsRequest,
  getAllTagsSuccess,
  getAllTagsFailure,
  starClaimRequest,
  starClaimSuccess,
  starClaimFailure,
  unStarClaimRequest,
  unStarClaimSuccess,
  unStarClaimFailure,
  resetMemoParams,
  getFilterTimePeriodSuccess,
  setLoadingAction,
} = claims.actions;

export const getClaimList =
(
  config: AxiosRequestConfig,
  lobId?: string | number,
  props = {}
): AppThunk<Promise<unknown>> => async dispatch => {
  dispatch(getClaimListRequest());

  const params = {
    lobId,
    ...props,
    clientId,
  };

  try {
    const { data } = await request.get('claimSearchFilter', {
      params,
      ...config,
    });

    dispatch(getClaimListSuccess(data));

    return data;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      dispatch(getClaimListCancelled());
    } else {
      const { response: { data } } = error;

      dispatch(getClaimListFailure(data));
    }
  }
};

export const getSimilarClaims = (
  claimId: number,
  props = {}
): AppThunk<Promise<unknown>> => async dispatch => {
  dispatch(getSimilarClaimsRequest());

  const params = {
    ...props,
    claimId,
  };

  try {
    const { data } = await request.get('similarClaims', { params });

    dispatch(getSimilarClaimsSuccess(data));

    return data;
  } catch ({ response: { data } }) {
    dispatch(getSimilarClaimsFailure(data));
  }
};

export const getFilterOptions = (): AppThunk<Promise<unknown>> => dispatch => {
  dispatch(getFilterOptionsRequest());

  return request
    .get('filterFill', { params: { clientId } })
    .then(({ data }) => {
      dispatch(getFilterOptionsSuccess(data));

      return data;
    })
    .catch(({ response: { data } }) => {
      dispatch(getFilterOptionsFailure(data));
    });
};

export const getFilterTimeOptions = (): AppThunk<Promise<unknown>> => dispatch => {
  dispatch(getFilterOptionsRequest());

  return request
    .get('timePeriodFilters', {
      params: { clientId },
      convertResponse: false,
    })
    .then(({ data: { data } }: any) => {
      dispatch(getFilterTimePeriodSuccess(data));

      return data;
    })
    .catch(({ response: { data } }) => {
      dispatch(getFilterOptionsFailure(data));
    });
};

export const getScoreColorSchema = (): AppThunk<Promise<unknown>> => dispatch => {
  dispatch(getScoreColorSchemaRequest());

  return request
    .get('scoreColorSchema', { params: { clientId } })
    .then(({ data }) => {
      dispatch(getScoreColorSchemaSuccess(data));

      return data;
    })
    .catch(({ response: { data } }) => {
      dispatch(getScoreColorSchemaFailure(data));
    });
};

export const setClaimTags = (
  claimId: number,
  tags: Array<string>,
  userEmail: string
): AppThunk<Promise<unknown>> => dispatch => {
  dispatch(setClaimTagsRequest());

  return request
    .get('setTags', {
      params: {
        claimId,
        userEmail,
        tags: tags.join(','),
      },
    })
    .then(({ data }) => {
      dispatch(setClaimTagsSuccess({
        claimId,
        tags,
      }));

      return data;
    })
    .catch(({ response: { data } }) => {
      dispatch(setClaimTagsFailure(data));
    });
};

export const getAllTags = (userEmail: string): AppThunk<Promise<unknown>> => dispatch => {
  dispatch(getAllTagsRequest());

  return request
    .get('getClientTags', {
      params: {
        clientId,
        userEmail,
      },
    })
    .then(({ data }) => {
      dispatch(getAllTagsSuccess(data));

      return data;
    })
    .catch(() => {
      dispatch(getAllTagsFailure());
    });
};

export const getClaimsComparison = (
  claimIds: Array<number>,
  props = {}
): AppThunk<Promise<unknown>> => dispatch => {
  dispatch(getClaimsComparisonRequest());

  const [
    claimId_1,
    claimId_2,
  ] = claimIds;

  const params = {
    claimId_1,
    claimId_2,
    ...props,
  };

  return request
    .get('comparisonClaims', { params })
    .then(({ data }) => {
      dispatch(getClaimsComparisonSuccess(data));

      return data;
    })
    .catch(({ response: { data } }) => {
      dispatch(getClaimsComparisonFailure(data));
    });
};

export const starClaim = (
  claimId: number,
  email: string
): AppThunk<Promise<unknown>> => dispatch => {
  dispatch(starClaimRequest({ claimId }));

  const params = {
    claimId,
    userEmail: email,
  };

  return request
    .get('starClaim', { params })
    .then(({ data }) => {
      dispatch(starClaimSuccess());

      return data;
    })
    .catch(({ response: { data } }) => {
      dispatch(starClaimFailure(data));
    });
};

export const setLoading = (isLoading: boolean): AppThunk<void> => dispatch => {
  dispatch(setLoadingAction(isLoading));
};

export const unStarClaim = (
  claimId: number,
  email: string
): AppThunk<Promise<unknown>> => dispatch => {
  dispatch(unStarClaimRequest({ claimId }));

  const params = {
    claimId,
    userEmail: email,
  };

  return request
    .get('unStarClaim', { params })
    .then(({ data }) => {
      dispatch(unStarClaimSuccess());

      return data;
    })
    .catch(({ response: { data } }) => {
      dispatch(unStarClaimFailure(data));
    });
};

export const selectClaimList = (state: RootState) => state.claims.claimList;
export const selectIsLoading = (state: RootState) => state.claims.isLoading;

export const selectSimilarClaims = (state: RootState) => state.claims.similarClaims;
export const selectIsSimilarClaimsLoading = (state: RootState) => (
  state.claims.isSimilarClaimsLoading
);

export const selectComparedClaims = (state: RootState) => state.claims.comparison;
export const selectIsComparisonLoading = (state: RootState) => (
  state.claims.isComparisonLoading
);

export const selectIsFilterOptionsLoading = (state: RootState) => (
  state.claims.isFilterOptionsLoading
);

export const selectIsStarClaimLoading = (state: RootState) => (
  state.claims.isStarClaimLoading
);

export const selectFilterOptions = (state: RootState) => state.claims.filterOptions;

export const selectAllTags = (state: RootState) => state.claims?.tags?.tags;
export const selectIsTagsLoading = (state: RootState) => state.claims.isTagsLoading;

export const selectScoreColorSchema = (state: RootState) => state.claims.scoreColorSchema;

//  // TODO: this select BodyPart List and convert it to the acceptable
// format for input delete if input will be fixed
// export const SelectBodyFilterOption = (property: string)
//  => (state: RootState) => state?.claims?.filterOptions[property]?.map(value => ({
//   value,
//   label: value,
// }));

export default claims.reducer;
